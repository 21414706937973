<template>
  <div>
    <!--导航条-->
    <nav-box></nav-box>
    <div class="noticeDetail"><span class="noticeDetail_title">公告名称:</span>{{ noticeDetail.title }}</div>

    <div class="w">
      <div class="tabComponents">
        <table class="myTable" :style="styleObject">
          <tr v-for="(item,i) in tableData" :key="i">
            <td>{{ item.key }}</td>
            <td class="myTable_column" style="width: 449px;"><span style="text-align: left"
                                                                   v-html="item.value">{{ item.value }}</span></td>
          </tr>
        </table>
        <table class="mailTable" :style="styleObject" v-if="s_showByRow">
          <tr v-for="index in rowCount" :key="index">
            <td>{{ doubleTableData[index * 2 - 2].key }}</td>
            <td class="column"><span>{{ doubleTableData[index * 2 - 2].value }}</span></td>
            <td>{{ doubleTableData[index * 2 - 1] !== undefined ? doubleTableData[index * 2 - 1].key : '' }}</td>
            <td class="column"><span>{{ doubleTableData[index * 2 - 1].value }}</span></td>
          </tr>
        </table>
        <table class="mailTable" :style="styleObject" v-else>
          <tr v-for="index in rowCount" :key="index">
            <td>{{ tableData[index - 1].key }}</td>
            <td class="column"><span>{{ tableData[index - 1].value }}</span></td>
            <td>
              {{ tableData[rowCount + index - 1] !== undefined ? tableData[rowCount + index - 1].key : '' }}
            </td>
            <td class="column">
              {{ tableData[rowCount + index - 1] !== undefined ? tableData[rowCount + index - 1].value : '' }}
            </td>
          </tr>
        </table>

      </div>
    </div>

    <div class="w" style="text-align: center; margin-top: 20px;">
      <el-button @click="goBack" style="border-radius: 10px">返回</el-button>
    </div>

    <!--底部导航-->
    <qq-footer></qq-footer>

  </div>
</template>
<script>
// import commonTable from '@/components/tables.vue'

import NavBox from '@/components/common/navBox'
import QqFooter from '@/components/common/qqFooter'

export default {
  components: {
    QqFooter,
    NavBox
  },
  data () {
    return {
      noticeDetail: {},
      tableData: [],
      doubleTableData: [],
      exraObj: {},
      styleObject: {},
      s_showByRow: true
    }
  },
  computed: {
    rowCount: function () {
      return Math.ceil(this.doubleTableData.length / 2)
    }
  },
  created () {
    const id = this.$route.query.id
    this.styleObject = this.tableStyle
    if (this.showByRow !== undefined) {
      this.s_showByRow = this.showByRow
    }
    this.getNoticeDetail(id)
  },
  methods: {
    getNoticeDetail (id) {
      this.axios.get(`${this.getaway.oa}/srm/notices/${id}`, {}).then((res) => {
        this.noticeDetail = res.data
        this.tableData = JSON.parse(res.data.content)
      })
    },

    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.noticeDetail {
  text-align: center;
  margin: 20px;
  color: #999999;
}

.w {
  width: 1200px;
  margin: 0 auto;
}

.noticeDetail_title {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
  color: #666666;
}

/*.tabComponents {
  !*position: relative;*!
  width: 1000px;
  margin: 0 auto;
}*/

.commonTable {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>
<style scoped="less">
.myTable {
  width: 1200px;
  border-collapse: collapse;
  /*margin: 0 auto;*/
  font-size: 15px;
  color: #71787E;
  text-align: center;
}

.myTable tr td.column {
  background-color: #ffffff;

  color: #393C3E;
}

.myTable tr td {
  border: 1px solid #E6EAEE;
  width: 280px;
  height: 65px;
  line-height: 65px;
  box-sizing: border-box;
  padding: 10px;
}

.myTable td,
.myTable th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 60px;
}

/deep/ .el-input__inner {
  border: 1px solid transparent;
}

/*.singleTable*/
.mailTable, .mailTable tr, .mailTable tr td {
  /*border: 1px solid #E6EAEE;*/
}

.mailTable {
  text-align: center;
  font-size: 12px;
  color: #71787E;
}

.mailTable tr td {

  border: 1px solid #E6EAEE;
  border-top: none;
  border-right: none;
  width: 280px;
  height: 65px;
  line-height: 65px;
  /*box-sizing: border-box;*/
  padding: 10px;
}

.mailTable tr td:last-child {
  border-right: 1px solid #E6EAEE;
}

.mailTable tr td.column {
  background-color: #ffffff;
  color: #393C3E;
}

.myTable_column {
  background-color: #ffffff;
  color: #393C3E;
}
</style>
